<template>
  <div class="waylay">
    <el-card>
      <div slot="header" class="clearfix">
        <div style="margin-bottom:20px;">
          退货管理
        </div>

        <el-form :inline="true" id="el-form" ref="backForm" :model="backForm" class="demo-form-inline" @submit.native.prevent size="mini">
          <el-form-item label="入库快递单号" prop="backNo">
            <el-input v-model="backForm.backNo" @keyup.native.enter="nextFocus($event)"></el-input>
          </el-form-item>
          <el-form-item label="重量：KG" prop="backWeight">
            <el-input v-model="backForm.backWeight" @keyup.native.enter="nextFocus($event)"></el-input>
          </el-form-item>
          <el-form-item >
            <el-button type="primary" @click="putSubmit" :disabled="isLoading">入库</el-button>
          </el-form-item>
        </el-form>
      </div>

      <el-form :inline="true" ref="form" :model="form" class="demo-form-inline" size="small">
        <el-form-item label="退回快递单号" prop="backNo">
          <el-input v-model="form.backNo" clearable size="small"></el-input>
        </el-form-item>
        <el-form-item label="原快递单号" prop="backNo">
          <el-input v-model="form.mailNo" clearable size="small"></el-input>
        </el-form-item>
        <el-form-item label="退回录入时间：">
          <el-date-picker
            v-model="form.dateQuery"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss' >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="退回状态：" prop="backStatus">
          <el-select v-model="form.backStatus" placeholder="全部" clearable>
            <el-option v-for="item in backStatusList" :key="item.status" :label="item.statusDesc"
                       :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退回归类">
          <el-select v-model="form.backSortOut" placeholder="全部" clearable>
            <el-option v-for="item in backSortOutList" :key="item.status" :label="item.statusDesc" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" size="mini" :disabled="isLoading">查询</el-button>
        </el-form-item>
        <el-form-item class="btn-right">
          <el-button @click="showDialogInit()" size="mini" type="danger">添加退回件</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-table
          :data="tableData"
          border
          v-loading="isLoading"
          style="width: 100%" size="small">
          <el-table-column type="index" label="编号" width="80"></el-table-column>
          <el-table-column prop="backNo" label="退回快递单号"></el-table-column>
          <el-table-column prop="mailNo" label="原快递单号"></el-table-column>
          <el-table-column prop="backPhone" label="退回件登记手机号"></el-table-column>
          <el-table-column prop="backWeight" label="退回入库重量(KG)"></el-table-column>
          <el-table-column prop="packWeight" label="出库打包重量(KG)"></el-table-column>
          <el-table-column prop="backProd" label="退回商品"></el-table-column>
          <el-table-column prop="agent" label="代理"></el-table-column>
          <el-table-column prop="buyName" label="买家"></el-table-column>
          <el-table-column prop="buyPhone" label="买家手机号"></el-table-column>
          <el-table-column prop="buyProvince" label="买家省份"></el-table-column>
          <el-table-column prop="backUrl" label="退回物流信息链接">
            <template slot-scope="scope">
              <a :href="scope.row.backUrl" v-if="scope.row.backUrl!==null" target="_blank" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;color:#FFA500;">查看物流信息</a>
              <el-button @click="handleGet(scope.row)" size="mini" type="text" v-else>获取链接</el-button>
            </template>
          </el-table-column>
          <el-table-column label="退回录入时间">
            <template slot-scope="scope">
              <span>{{ scope.row.backTime | dateFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column  label="退回状态">
            <template slot-scope="scope">
              <span>{{ scope.row.backStatus | backStatusFormate}}</span>
            </template>
          </el-table-column>
          <el-table-column label="退回归类">
            <template slot-scope="scope">
              <span>{{ scope.row.backSortOut | backSortOutFormate}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="240" fixed="right">
            <template slot-scope="{row}">
              <el-button @click="tackList(row)" type="success" size="mini">跟进记录</el-button>
              <el-button @click="tackCreat(row)" type="warning" size="mini" v-if="'2'!==row.backStatus">跟进</el-button>
              <el-button @click="edit(row)" type="danger" size="mini" >编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      分页组件结构-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10,50,100,200]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataTotal"
        :disabled="isLoading"
      >
      </el-pagination>

      <!--  添加弹窗-->
      <div v-if="dialogVisibleInit">
        <el-dialog
          title="添加退回件"
          :visible.sync="dialogVisibleInit"
          width="60%"
          :before-close="handleCancelInit">
          <!--将添加与编辑功能单独封装到组件中-->
          <create  @cancel="handleCancelInit" @success="handleSuccessInit"></create>
        </el-dialog>
      </div>

      <!--  编辑弹窗-->
      <div v-if="dialogVisibleEdit">
        <el-dialog
          title="编辑退回件信息"
          :visible.sync="dialogVisibleEdit"
          width="60%"
          :before-close="handleCancelEdit">
          <!--将添加与编辑功能单独封装到组件中-->
          <edit :back-info="backInfo"  @cancel="handleCancelEdit" @success="handleSuccessEdit"></edit>
        </el-dialog>
      </div>

      <!--  跟进弹窗-->
      <div v-if="dialogVisibleTack">
        <el-dialog
          title="添加跟进"
          :visible.sync="dialogVisibleTack"
          width="60%"
          :before-close="handleCancelTack">
          <!--将添加与编辑功能单独封装到组件中-->
          <tack :back-status="backStatus" :back-sort-out="backSortOut" :back-no="backNo" @cancel="handleCancelTack" @success="handleSuccessTack"></tack>
        </el-dialog>
      </div>

      <!--  跟进记录弹窗-->
      <div v-if="dialogVisibleTackList">
        <el-dialog
          title="跟进记录"
          :visible.sync="dialogVisibleTackList"
          width="60%"
          :before-close="handleCancelTackList">
          <!--将添加与编辑功能单独封装到组件中-->
          <tack-list  :back-no="backNo" @cancel="handleCancelTackList" ></tack-list>
        </el-dialog>
      </div>

    </el-card>

  </div>
</template>

<script>
import {dateFormate} from '@/utils/dateUtil'
import {findAllBackStatus,findAllBackSortOut} from '@/services/status'
import {findAllBackInfoByPage,getBackUrl,backStorage} from '@/services/logistics'
import Create from './components/Create'
import Tack from "./components/Tack";
import Edit from "./components/Edit";
import {backStatusToCn,backSortOutToCn} from '@/utils/enums'
import TackList from "./components/TackList";
import Speech from 'speak-tts'
import {getUserInfo} from '@/services/user'
export default {
  name: 'back',
  components: {TackList, Tack, Create,Edit },
  data() {
    return {
      userInfo: {},
      tableData: [],
      backForm: {},
      form: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10
      },
      // 数据总数
      dataTotal: 0,
      isLoading: false,
      // 用于存储拦截状态列表
      backStatusList: [],
      backSortOutList: [],
      dialogVisibleInit: false,
      dialogVisibleTack: false,
      dialogVisibleEdit: false,
      dialogVisibleTackList: false,
      backSortOut: null,
      backStatus: null,
      backNo: null,
      mailNo: null,
      backInfo: {}
    }
  },
  mounted() {
    this.speechInit();
  },
  methods: {
    pushToPhonePage() {
      this.$router.push('/phone/back');
    },
    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },
    speechInit(){
      this.speech = new Speech();
      this.speech.setLanguage('zh-CN');
      this.speech.init().then(()=>{
        console.log('语音播报初始化完成...')
      })
    },

    //语音播报
    speak(msg){
      this.speech.speak({text:msg}).then(()=>{
        console.log("播报完成...")
      })
    },
    async backOn() {
      if (!this.backForm || !this.backForm.backWeight|| !this.backForm.backNo) {
        this.resetMailNoAndWeightNull()
        this.speak('单号及重量必须输入，请重新扫描')
        return this.$message.error('单号及重量必须输入，请重新扫描')
      }
      if (this.backForm.backWeight.trim().length>8) {
        this.resetMailNoAndWeightNull()
        this.speak('重量有问题，请重新扫描')
        return this.$message.error('重量有问题，请重新扫描')
      }
      if (this.backForm.backNo.trim().length<=12) {
        this.resetMailNoAndWeightNull()
        this.speak('快递单号有问题，请重新扫描')
        return this.$message.error('快递单号有问题，请重新扫描')
      }
      const params = {
        backNo: this.backForm.backNo,
        backWeight: this.backForm.backWeight.trim(),
        tackPhone: this.userInfo.phone,
        tackName: this.userInfo.userName,
      }
      const {data} = await backStorage(params)
      this.resetMailNoAndWeightNull()
      if (data.code === 200) {
        this.speak('入库成功')
        this.$message.success('入库成功')
      } else {
        if (data.message) {
          this.speak(data.message)
        } else {
          this.speak(data.data)
        }
        this.$message.error(data.message)
      }
    },
    // 重置上传单号和重量
    resetMailNoAndWeightNull() {
      this.backForm.backNo = ''
      this.backForm.backWeight = ''
    },
    initInputDOM() {
      //获取id为el-form下的所有input 框
      const inputDoms = document.querySelectorAll(
        "#el-form .el-input__inner"
      );
      //遍历这个input框给他们一个标识
      inputDoms.forEach((item, index) => {
        item.setAttribute("data-index", index);
      });
      this.inputDoms = inputDoms;
    },
    //回车事件
    nextFocus(event) {
      const index = event.target.getAttribute("data-index");
      const nextIndex = parseInt(index) + 1;
      const length = this.inputDoms.length;
      if (nextIndex < length) {
        this.inputDoms[nextIndex].focus();
      } else {
        this.putSubmit()
        this.inputDoms[0].focus();
      }
    },
    // 入库
    async putSubmit() {
      await this.backOn()
      this.form.currentPage = 1
      await this.loadAllBackInfoByPage()
    },
    async getH5Url(row) {
      // 获取物流信息链接
      const {data} = await getBackUrl(row)
      if (200 !== data.code) {
        this.$message.error('获取物流信息链接失败')
      }
      await this.loadAllBackInfoByPage()
    },
    handleGet(row) {
      this.getH5Url(row)
    },
    // 监听子组件的添加状态，成功时触发
    handleSuccessInit () {
      // 隐藏对话框
      this.dialogVisibleInit = false
      // 刷新列表
      this.loadAllBackInfoByPage()
    },

    // 监听子组件的取消状态
    handleCancelInit () {
      // 隐藏对话框
      this.dialogVisibleInit = false
    },



 // 监听子组件的添加状态，成功时触发
    handleSuccessTack () {
      // 隐藏对话框
      this.dialogVisibleTack = false
      // 刷新列表
      this.loadAllBackInfoByPage()
    },

    // 监听子组件的取消状态
    handleCancelTack () {
      // 隐藏对话框
      this.dialogVisibleTack = false
      this.backStatus = null
      this.backSortOut = null
      this.backNo = null
    },

    // 监听子组件的添加状态，成功时触发
    handleSuccessEdit () {
      // 隐藏对话框
      this.dialogVisibleEdit = false
      // 刷新列表
      this.loadAllBackInfoByPage()
    },
    // 监听子组件的取消状态
    handleCancelEdit () {
      // 隐藏对话框
      this.dialogVisibleEdit = false
      this.backInfo = {}
    },

    // 监听子组件的取消状态
    handleCancelTackList() {
      // 隐藏对话框
      this.dialogVisibleTackList = false
      this.backNo = null
    },

    //
    // 每页显示条数变化时触发
    handleSizeChange(val) {
      this.form.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form.currentPage = 1
      this.loadAllBackInfoByPage()
    },
    // 页号改变时触发
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.loadAllBackInfoByPage()
    },

    // 添加筛选后的数据查询
    async onSubmit() {
      // 请求数据前，将请求的页数更新为1
      this.form.currentPage = 1
      await this.loadAllBackInfoByPage()
    },

    async loadBackStatus() {
      const {data} = await findAllBackStatus()
      if (200 === data.code) {
        this.backStatusList = data.data
      } else {
        this.$message.error('查询状态列表失败')
      }
    },
    async loadBackSortOut() {
      const {data} = await findAllBackSortOut()
      if (200 === data.code) {
        this.backSortOutList = data.data
      } else {
        this.$message.error('查询归类列表失败')
      }
    },
    async loadAllBackInfoByPage() {
      // 开始加载数据
      this.isLoading = true
      await this.loadUserInfo()
      this.form.deptSortOut = this.userInfo.company
      if (this.form && this.form.dateQuery) {
        let dateBegin = this.form.dateQuery[0]
        let dateEnd = this.form.dateQuery[1]
        this.form.dateBegin = dateBegin
        this.form.dateEnd = dateEnd
      }
      const {data} = await findAllBackInfoByPage(this.form)
      if (200 === data.code) {
        this.tableData = data.data.records
        this.dataTotal = data.data.total
      }
      this.isLoading = false
    },
    // 显示对话框
    showDialogInit() {
      this.dialogVisibleInit = true
    },

    // 显示编辑对话框
    edit(row) {
      this.backInfo = row
      this.dialogVisibleEdit = true
    },

    // 显示跟进对话框
    tackCreat(row) {
      this.backStatus = row.backStatus
      this.backSortOut = row.backSortOut
      this.backNo = row.backNo
      this.dialogVisibleTack = true
    },
     // 显示跟进记录对话框
    tackList(row) {
      this.backNo = row.backNo
      this.dialogVisibleTackList = true
    },


  },
  created() {
    this.$nextTick(() => {
      this.initInputDOM();
    })
    if (this.$route.query&&this.$route.query.backStatus) {
      this.form.backStatus = this.$route.query.backStatus
    }
    this.loadBackStatus()
    this.loadBackSortOut()
    this.loadAllBackInfoByPage()
    this.loadUserInfo()
  },
  filters: {
    // 日期过滤器
    dateFormate(date) {
      return dateFormate(date)
    },
    backStatusFormate(code) {
      return backStatusToCn(code)
    },

    backSortOutFormate(code) {
      return backSortOutToCn(code)
    },

  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}

.btn-right {
  float: right;
}

.el-table {
  margin-top: 10px;
}

.btn-div {
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
