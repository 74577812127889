<template>
  <div class="create-or-edit">
    <div>
      <el-form :model="backInfo" label-position="left" label-width="120px" size="small">
        <el-form-item label="退回快递单号">
          <el-input v-model="backInfo.backNo"></el-input>
        </el-form-item>
        <el-form-item label="退回手机号">
          <el-input v-model="backInfo.backPhone"></el-input>
        </el-form-item>
        <el-form-item label="原快递单号">
          <el-input v-model="backInfo.mailNo"></el-input>
        </el-form-item>
        <el-form-item label="退回入库重量(KG)">
          <el-input v-model="backInfo.backWeight"></el-input>
        </el-form-item>
        <el-form-item label="出库打包重量(KG)">
          <el-input v-model="backInfo.packWeight"></el-input>
        </el-form-item>
        <el-form-item label="退回商品">
          <el-input v-model="backInfo.backProd"></el-input>
        </el-form-item>
        <el-form-item label="代理">
          <el-input v-model="backInfo.agent"></el-input>
        </el-form-item>
        <el-form-item label="买家姓名">
          <el-input v-model="backInfo.buyName"></el-input>
        </el-form-item>
        <el-form-item label="买家电话">
          <el-input v-model="backInfo.buyPhone"></el-input>
        </el-form-item>
        <el-form-item label="买家省份">
          <el-input v-model="backInfo.buyProvince"></el-input>
        </el-form-item>
        <el-form-item label="退回描述">
          <el-input type="textarea" placeholder="请输入退回原因，不超过200字" v-model="backInfo.tackDesc"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onsubmit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import {getUserInfo} from '@/services/user'
import { updateBackNoInfo } from '@/services/logistics'

export default {
  name: 'Edit',
  props: {
    backInfo: {
      type: [Object]
    },
  },
  created () {
    this.loadUserInfo()
  },
  data () {
    return {
      userInfo: {},
    }
  },
  methods: {
    onCancel () {
      //设置取消状态，让父组件处理
      this.$emit('cancel')
      this.backInfo = {}
    },
    async onsubmit () {
      this.backInfo.tackName=this.userInfo.userName
      this.backInfo.tackPhone=this.userInfo.phone
      const { data } = await updateBackNoInfo(this.backInfo)
      if (200 === data.code) {
        // 关闭提示框(需要子组件向父组件传递状态)
        this.$emit('success')
        // 设置成功提示
        this.$message.success('修改成功')
      } else {
        this.$message.error(data.message)
      }
    },
    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  //设置内部元素水平居中
  justify-content: center;
  //设置内部元素垂直居中
  align-items: center;
}
</style>
