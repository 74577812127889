<template>

    <div class="list">
      <div>
        <el-table
          :data="tableData"
          border
          v-loading="isLoading"
          style="width: 100%" size="small">
          <el-table-column type="index" label="编号" width="80"></el-table-column>
          <el-table-column prop="mailNo" label="快递单号"></el-table-column>
          <el-table-column prop="tackName" label="跟进员"></el-table-column>
          <el-table-column prop="tackPhone" label="跟进员手机号"></el-table-column>
          <el-table-column prop="tackDesc" label="跟进描述"></el-table-column>
          <!-- 设置过滤器需要使用作用域插槽获取数据-->
          <el-table-column label="跟进时间">
            <template slot-scope="scope">
              <span>{{ scope.row.tackTime | dateFormate }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onCancel" type="success">返回</el-button>
      </div>
    </div>

</template>

<script>
import {dateFormate} from '@/utils/dateUtil'
import {backTackList} from "@/services/logistics";
export default {
  name: 'TackList',
  props: {
    backNo: {
      type: [String]
    }
  },
  created () {
    this.loadAllBackTackList()
  },
  data () {
    return {
      tableData: [],
      isLoading: false,
    }
  },
  methods: {
    onCancel () {
      //设置取消状态，让父组件处理
      this.$emit('cancel')
      this.tableData = []
    },

    async loadAllBackTackList() {
      // 开始加载数据
      this.isLoading = true
      const params = {
        backNo:this.backNo
      }
      const {data} = await backTackList(params)
      if (200 === data.code) {
        this.tableData = data.data
      } else {
        this.$message.error("加载数据失败")
      }
      this.isLoading = false
    },
  },
  filters: {
    // 日期过滤器
    dateFormate(date) {
      return dateFormate(date)
    },

  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  //设置内部元素水平居中
  justify-content: center;
  //设置内部元素垂直居中
  align-items: center;
  margin-top: 10px;
}
</style>
