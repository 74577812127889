<template>
  <div class="create-or-edit">
    <div>
      <el-form :model="form" label-position="left" label-width="80px" size="small">
        <el-form-item label="退回快递单号" >
          <el-input v-model="form.backNo" disabled></el-input>
        </el-form-item>
        <el-form-item label="退回状态">
          <el-select v-model="form.backStatus" placeholder="请选择状态" clearable>
            <el-option v-for="item in backStatusList" :key="item.status" :label="item.statusDesc" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退回归类">
          <el-select v-model="form.backSortOut" placeholder="请选择归类" clearable>
            <el-option v-for="item in backSortOutList" :key="item.status" :label="item.statusDesc" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退回描述">
          <el-input type="textarea" placeholder="请输入原因，不超过200字" v-model="form.tackDesc"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" @click="onsubmit">确 定</el-button>
    </div>
  </div>
</template>

<script>
import {getUserInfo} from '@/services/user'
import { backTack } from '@/services/logistics'
import {findAllBackSortOut, findAllBackStatus} from "@/services/status";
export default {
  name: 'Tack',
  props: {
    backStatus: {
      type: [String]
    },
    backSortOut: {
      type: [String]
    },
    backNo: {
      type: [String]
    }
  },
  created () {
    this.loadBackSortOut()
    this.loadBackStatus()
    this.loadUserInfo()
    this.form.backStatus = this.backStatus
    this.form.backNo = this.backNo
    this.form.backSortOut = this.backSortOut
  },
  data () {
    return {
      userInfo: {},
      form: {
        backNo: '',
        tackDesc: '',
        tackName: '',
        tackPhone: '',
        backStatus: '',
        backSortOut:'',
      },
      backStatusList: [],
      backSortOutList: [],
    }
  },
  methods: {
    onCancel () {
      //设置取消状态，让父组件处理
      this.$emit('cancel')
      this.form = {}
    },
    async onsubmit () {
      this.form.tackName=this.userInfo.userName
      this.form.tackPhone=this.userInfo.phone
      const { data } = await backTack(this.form)
      if (200 === data.code) {
        // 关闭提示框(需要子组件向父组件传递状态)
        this.$emit('success')
        // 设置成功提示
        this.$message.success('添加成功')
        // 清除表单内容
        this.form = {}
      } else {
        this.$message.error(data.message)
      }
    },
    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },
    async loadBackStatus() {
      const {data} = await findAllBackStatus()
      if (200 === data.code) {
        this.backStatusList = data.data
      } else {
        this.$message.error('查询状态列表失败')
      }
    },
    async loadBackSortOut() {
      const {data} = await findAllBackSortOut()
      if (200 === data.code) {
        this.backSortOutList = data.data
      } else {
        this.$message.error('查询归类列表失败')
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  //设置内部元素水平居中
  justify-content: center;
  //设置内部元素垂直居中
  align-items: center;
}
</style>
